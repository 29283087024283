import { Box, Container, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useGetLogo from "src/components/logo/hooks/use-logo";

const AuthLayout = ({ children }) => {
  const logo = useGetLogo();
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: palette.background.neutral,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        py: { xs: 2, md: 4 }, // Adjust padding for mobile and desktop
      }}
    >
      {/* Desktop and Tablet View */}
      <Container
        maxWidth="sm"
        sx={{
          display: { xs: "none", md: "block" }, // Hide container on mobile view
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            padding: { xs: 3, md: 5 }, // Add padding for mobile and desktop
            borderRadius: 2,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        >
          <Box sx={{ mb: { xs: 2, md: 3 }, textAlign: "center" }}>
            <img
              style={{ width: "50%", height: "50%" }} // Adjust size as needed
              src={logo}
              alt="Logo"
            />
          </Box>
          {children}
        </Paper>
      </Container>

      {/* Mobile View (without container and background) */}
      <Box
        sx={{
          p: 2,
          display: { xs: "block", md: "none" }, // Show only on mobile
          width: "100%",
          textAlign: "center",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <img
            style={{ width: "50%", height: "50%" }} // Adjust size as needed
            src={logo}
            alt="Logo"
          />
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;